


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IAntiEmu } from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class AntiEmu extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public headers = [
    {
      text: 'Method',
      sortable: true,
      value: 'prop',
      align: 'left',
    },
    {
      text: 'Property Value',
      sortable: true,
      value: 'origin_value',
      align: 'left',
    },
  ];

  public antiEmus: IAntiEmu[] = [];

  get token() {
    return readToken(this.$store);
  }

  public async getAntiEmus() {
    if (this.fileId && this.fileId !== '') {
      const antiEmuResult = await analysisAPI.getAntiEmus(
        this.token,
        this.fileId,
      );
      this.antiEmus = antiEmuResult.data;
      const antiRootResult = await analysisAPI.getAntiRoot(
        this.token,
        this.fileId,
      );
      this.antiEmus.push(...antiRootResult.data);
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getAntiEmus();
  }
}
