











import { Component, Prop, Vue } from 'vue-property-decorator';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class Pcap extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public items: any[] = [];
  get token() {
    return readToken(this.$store);
  }

  public async getPcap() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getPcap(this.token, this.fileId);

      const blob = new Blob([res.data]);
      const anchor: any = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.setAttribute('download', this.fileId + '.pcap');

      try {
        const evt = document.createEvent('MouseEvents');
        evt.initEvent('click', true, true);
        anchor.dispatchEvent(evt);
      } catch (error) {
        window.navigator.msSaveBlob(blob, anchor.download);
      }
    }
  }
}
