



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import AndroidInfo from './AndroidInfo.vue';
import Permission from './Permission.vue';
import Activity from './Activity.vue';
import Service from './Service.vue';
import Receiver from './Receiver.vue';
import Provider from './Provider.vue';
import Certificate from './Certificate.vue';
import IntentFilter from './IntentFilter.vue';

@Component({
  components: {
    AndroidInfo,
    Permission,
    Activity,
    Service,
    Receiver,
    Provider,
    Certificate,
    IntentFilter,
  },
})
export default class StaticResult extends Vue {
  @Prop({ type: String }) public fileId!: string;
}
