















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ICipher } from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class Cipher extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public headers = [
    {
      text: 'Algorithm',
      sortable: true,
      value: 'algo',
      align: 'left',
    },
    {
      text: 'Method',
      sortable: false,
      value: 'method',
      align: 'left',
    },
    {
      text: 'Input',
      sortable: false,
      value: 'in_value',
      align: 'left',
    },
    {
      text: 'Output',
      sortable: false,
      value: 'out_value',
      align: 'left',
    },
    {
      text: 'Extra Info',
      sortable: false,
      value: 'extras',
      align: 'left',
    },
    {
      text: 'Created',
      sortable: true,
      value: 'created',
      align: 'left',
    },
  ];

  public ciphers: ICipher[] = [];

  get token() {
    return readToken(this.$store);
  }

  public async getCiphers() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getCiphers(this.token, this.fileId);
      this.ciphers = res.data;
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getCiphers();
  }
}
