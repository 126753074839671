

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IHostInfo } from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class HostInfo extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public headers = [
    {
      text: 'IP',
      sortable: true,
      value: 'ip',
      align: 'left',
    },
    {
      text: 'Status',
      sortable: false,
      value: 'is_bad',
      align: 'left',
    },
    {
      text: 'Country',
      sortable: true,
      value: 'country',
      align: 'left',
    },
    {
      text: 'City',
      sortable: true,
      value: 'city',
      align: 'left',
    },
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Range',
      sortable: true,
      value: 'range',
      align: 'left',
    },
    {
      text: 'Emails',
      sortable: true,
      value: 'emails',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Created',
      sortable: true,
      value: 'created',
      align: 'left',
    },
    {
      text: 'Updated',
      sortable: true,
      value: 'updated',
      align: 'left',
    },
  ];

  public hostInfos: IHostInfo[] = [];

  get token() {
    return readToken(this.$store);
  }

  public async getHostInfos() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getHostInfos(this.token, this.fileId);
      this.hostInfos = res.data;
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getHostInfos();
  }
}
