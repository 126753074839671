










import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { taskAPI } from '@/apis';
import { readToken } from '@/store/main/getters';

@Component
export default class AnalysisMonitor extends Vue {
  public fileId: string = '';
  public analysisId: string = '';

  public show: boolean = false;
  public duration: number = 0;
  private interval: any = 0;

  public async refresh() {
    const res = await taskAPI.getStatus(this.token, this.analysisId);
    if (res.data.status === 'finished') {
      this.show = false;
      this.duration = 0;
      clearInterval(this.interval);
      this.$router.push({
        name: 'analysis-result',
        params: { fid: this.fileId },
      });
    }

    if (res.data.status === 'error') {
      clearInterval(this.interval);
      this.$router.push({
        path: 'analyses/view',
      });
    }
  }

  @Watch('analysisId', { immediate: true })
  public async onAnalysisChanged() {
    if (this.analysisId !== '') {
      this.show = true;
      this.duration = 0;
      this.interval = window.setInterval(() => {
        this.refresh();
      }, 2000);
    }
  }

  @Watch('duration', { immediate: true })
  public async onTimer() {
    setTimeout(() => {
      this.duration++;
    }, 1000);
  }
  get token() {
    return readToken(this.$store);
  }

  private mounted() {
    const { fid, aid } = this.$route.params;
    this.fileId = fid;
    this.analysisId = aid;
  }

  private beforeDestroy() {
    clearInterval(this.interval);
  }
}
