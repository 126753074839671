





















































import { Component, Vue, Watch } from 'vue-property-decorator';
import BehaviorResult from './behavior/BehaviorResult.vue';
import StaticResult from './static/StaticResult.vue';
import { readToken } from '@/store/main/getters';
import { fileAPI } from '@/apis';

@Component({
  components: {
    StaticResult,
    BehaviorResult,
  },
})
export default class AnalysisDetails extends Vue {
  public fileId: string = '';

  public doRescan() {
    if (this.fileId !== '' && this.token !== '') {
      fileAPI
        .rescan(this.token, this.fileId)
        .then((res: any) => {
          const { sha256, task_id } = res.data;
          this.$router.push({
            name: 'analysis-monitor',
            params: { fid: sha256, aid: task_id },
          });
        })
        .catch((err: any) => {
          console.error('ERROR: ', err);
        });
    }
  }
  private mounted() {
    const { fid } = this.$route.params;
    this.fileId = fid;
  }

  @Watch('$route.params', { immediate: true })
  private onRouteChange() {
    const { fid } = this.$route.params;
    this.fileId = fid;
  }
  get token() {
    return readToken(this.$store);
  }
}
