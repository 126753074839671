






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IDomainInfo } from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class DomainInfo extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public headers = [
    {
      text: 'Domain',
      sortable: true,
      value: 'query',
      align: 'left',
    },
    {
      text: 'Status',
      sortable: false,
      value: 'is_bad',
      align: 'left',
    },
    {
      text: 'Country',
      sortable: true,
      value: 'country',
      align: 'left',
    },
    {
      text: 'Region',
      sortable: true,
      value: 'region',
      align: 'left',
    },
    {
      text: 'City',
      sortable: true,
      value: 'city',
      align: 'left',
    },
    {
      text: 'Created',
      sortable: true,
      value: 'created',
      align: 'left',
    },
  ];

  public domainInfos: IDomainInfo[] = [];

  get token() {
    return readToken(this.$store);
  }

  public async getDomainInfos() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getDomainInfos(this.token, this.fileId);
      this.domainInfos = res.data;
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getDomainInfos();
  }
}
