


























import { Component, Vue } from 'vue-property-decorator';
import { readToken } from '@/store/main/getters';
import { fileAPI } from '@/apis';
@Component
export default class AnalysisSearch extends Vue {
  public fileId: string = '';
  public notFound: boolean = false;

  get token() {
    return readToken(this.$store);
  }

  public async submit() {
    try {
      const _ = await fileAPI.getFile(this.token, this.fileId);
      this.$router.push({
        name: 'analysis-result',
        params: { fid: this.fileId },
      });
    } catch (err) {
      this.notFound = true;
    } finally {
      if (this.notFound) {
        this.$router.push({
          name: '404',
        });
      }
    }
  }

  public resetError() {
    this.notFound = false;
  }
}
