





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { INetworkAccess } from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class Network extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public headers = [
    {
      text: 'method',
      sortable: true,
      value: 'method',
      align: 'left',
    },
    {
      text: 'Protocol',
      sortable: true,
      value: 'protocol',
      align: 'left',
    },
    {
      text: 'target',
      sortable: true,
      value: 'target',
      align: 'left',
    },
  ];

  public networkAccess: INetworkAccess[] = [];

  get token() {
    return readToken(this.$store);
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getNetworkAccess();
  }

  private getColor(mode: string) {
    if (mode === 'GET') {
      return 'primary';
    }
    if (mode === 'POST') {
      return 'orange';
    }
    return '';
  }
  private async getNetworkAccess() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getNetworkAccess(this.token, this.fileId);
      this.networkAccess = res.data;
    }
  }
}
