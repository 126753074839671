
































































import { Component, Vue, Prop } from 'vue-property-decorator';
import AntiEmu from './AntiEmu.vue';
import FileInteractions from './FileInteractions.vue';
import Network from './Network.vue';
import SharedPreferences from './SharedPreferences.vue';
import HostInfo from './HostInfo.vue';
import DomainInfo from './DomainInfo.vue';
import Screenshot from './Screenshot.vue';
import Pcap from './Pcap.vue';
import Library from './Library.vue';
import StringInfo from './String.vue';
import Cipher from './Cipher.vue';
import DexLoader from './DexLoader.vue';

@Component({
  components: {
    AntiEmu,
    FileInteractions,
    Network,
    SharedPreferences,
    HostInfo,
    DomainInfo,
    Screenshot,
    Pcap,
    Library,
    StringInfo,
    Cipher,
    DexLoader,
  },
})
export default class StaticResult extends Vue {
  @Prop({ type: String }) public fileId!: string;
}
