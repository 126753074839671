















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IDexClassLoader } from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class DexLoader extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public headers = [
    {
      text: 'ClassName',
      sortable: true,
      value: 'cls_name',
      align: 'left',
    },
    {
      text: 'Dex Path',
      sortable: false,
      value: 'dex_path',
      align: 'left',
    },
    {
      text: 'Parent',
      sortable: true,
      value: 'parent_cls',
      align: 'left',
    },
  ];

  public dexLoaders: IDexClassLoader[] = [];

  get token() {
    return readToken(this.$store);
  }

  public async getDexLoaders() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getDexLoaders(this.token, this.fileId);
      this.dexLoaders = res.data;
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getDexLoaders();
  }
}
