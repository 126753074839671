















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ILibrary } from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class Library extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: false,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Created',
      sortable: true,
      value: 'created',
      align: 'left',
    },
  ];

  public libs: ILibrary[] = [];

  get token() {
    return readToken(this.$store);
  }

  public async getLibs() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getLibs(this.token, this.fileId);
      this.libs = res.data;
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getLibs();
  }
}
