



















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class AnalysisURL extends Vue {}
