





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IFileAccess } from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class FileInteractions extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public headers = [
    {
      text: 'Method',
      sortable: true,
      value: 'method',
      align: 'left',
    },
    {
      text: 'Action',
      sortable: true,
      value: 'operation',
      align: 'left',
    },
    {
      text: 'File',
      sortable: true,
      value: 'args',
      align: 'left',
    },
  ];

  public fileAccess: IFileAccess[] = [];

  get token() {
    return readToken(this.$store);
  }

  public getColor(mode: string) {
    if (mode === 'delete') {
      return 'red';
    }
    if (mode === 'write') {
      return 'orange';
    }
    if (mode === 'read') {
      return 'primary';
    }
    if (mode === 'open') {
      return 'primary';
    }
    return '';
  }

  public async getFileInteractions() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getFileInteractions(
        this.token,
        this.fileId,
      );
      this.fileAccess = res.data;
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getFileInteractions();
  }
}
