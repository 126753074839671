

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IActivity } from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class Activity extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
  ];

  public activities: IActivity[] = [];

  get token() {
    return readToken(this.$store);
  }

  public async getActivities() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getActivities(this.token, this.fileId);
      this.activities = res.data;
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getActivities();
  }
}
