






































import { Component, Vue } from 'vue-property-decorator';

import AnalysisFile from './AnalysisFile.vue';
import AnalysisURL from './AnalysisURL.vue';
import AnalysisSearch from './AnalysisSearch.vue';

@Component({
  components: {
    'analyses-file': AnalysisFile,
    'analyses-url': AnalysisURL,
    'analyses-search': AnalysisSearch,
  },
})
export default class Analysis extends Vue {}
