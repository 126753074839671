
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class Screenshot extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public items: any[] = [];

  get token() {
    return readToken(this.$store);
  }

  public async getImages() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getImages(this.token, this.fileId);
      this.items = res.data;
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getImages();
  }
}
