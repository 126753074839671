







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IPermission } from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class Permission extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Level',
      sortable: true,
      value: 'level',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
  ];

  public permissions: IPermission[] = [];
  get token() {
    return readToken(this.$store);
  }

  public getColor(level: string): string {
    if (level === 'dangerous') {
      return 'red';
    }

    if (level === 'normal') {
      return 'primary';
    }

    if (level === 'signature') {
      return 'yellow';
    }

    return '';
  }
  public async getPermissions() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getPermissions(this.token, this.fileId);
      this.permissions = res.data;
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getPermissions();
  }
}
