


















































import { Component, Vue } from 'vue-property-decorator';
import { fileAPI } from '@/apis';
import { readToken } from '@/store/main/getters';

@Component
export default class AnalysisFile extends Vue {
  public file: any = null;
  public enter: boolean = false;
  public progress: number = 0;
  public showDialog: boolean = false;

  public addDropFile(e: any) {
    this.showDialog = true;
    this.file = e.dataTransfer.files[0];

    const formData = new FormData();
    formData.append('file', this.file);

    fileAPI
      .uploadFile(this.token, formData, this.onProgressUpdate)
      .then((res: any) => {
        const { sha256, task_id } = res.data;
        this.$router.push({
          name: 'analysis-monitor',
          params: { fid: sha256, aid: task_id },
        });
      })
      .catch((err: any) => {
        console.error('ERROR: ', err);
      })
      .finally(() => (this.showDialog = false));
  }

  public onProgressUpdate(progressEvent: any) {
    this.progress = (progressEvent.loaded / progressEvent.total) * 100;
  }

  public clearFile(e: any) {
    this.file = null;
    this.enter = false;
  }

  get token() {
    return readToken(this.$store);
  }
}
