
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  ICertificate,
  ICertificateSubject,
  ICertificateIssuer,
} from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class Certificate extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public certAttributes: ICertificate = {
    algo: '',
    version: '',
    serial_number: '',
    valid_from: '',
    valid_to: '',
    sha1: '',
    sha256: '',
  };

  public certSubject: ICertificateSubject = {
    country_name: '',
    state_or_province_name: '',
    locality_name: '',
    organization_name: '',
    organization_unit_name: '',
    common_name: '',
  };
  public certIssuer: ICertificateIssuer = {
    country_name: '',
    state_or_province_name: '',
    locality_name: '',
    organization_name: '',
    organization_unit_name: '',
    common_name: '',
  };

  get token() {
    return readToken(this.$store);
  }

  public async getCertInfo() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getCerts(this.token, this.fileId);
      if (res.data.length === 0) {
        return;
      }
      const certData = res.data[0];

      const issue = JSON.parse(certData.issuer!);
      this.certIssuer = { ...issue };

      const subject = JSON.parse(certData.subject);
      this.certSubject = { ...subject };

      this.certAttributes = {
        algo: certData.algo,
        version: certData.version,
        serial_number: certData.serial_number,
        valid_from: certData.valid_from,
        valid_to: certData.valid_to,
        sha1: certData.sha1,
        sha256: certData.sha256,
      };
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getCertInfo();
  }
}
