




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IIntentFilter, IIntentFilterValue } from '@/types/analysis';
import { readToken } from '@/store/main/getters';
import { analysisAPI } from '@/apis';

@Component
export default class IntentFilter extends Vue {
  @Prop({ type: String }) public fileId!: string;

  public intentFilters: IIntentFilter[] = [];

  get token() {
    return readToken(this.$store);
  }

  public async getIntentFilters() {
    if (this.fileId && this.fileId !== '') {
      const res = await analysisAPI.getIntentFilters(this.token, this.fileId);
      this.intentFilters = [];
      for (const item of res.data) {
        const itemValue: any = JSON.parse(item.value!);
        const intenFilter: IIntentFilter = {
          name: item.name,
          type: item.type,
          action: itemValue.action?.join('\n'),
          category: itemValue.category?.join('\n'),
        };

        this.intentFilters.push(intenFilter);
      }
    }
  }

  @Watch('fileId', { immediate: true })
  public async onFileIdChanged() {
    this.getIntentFilters();
  }
}
